import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {API_URLS, PATH_URLS} from "../const";
import {ApiService} from "../services/api.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable()

export class UserUnbanResolver implements Resolve<any> {
  constructor(
    private api: ApiService,
    private router: Router,
    ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.api.get(`${API_URLS.currentUser}/${route.params['id']}`, true).pipe(
      map((res) => {
        if (res.data.status === 'blocked'){
          return this.router.navigate(['/404'], {skipLocationChange: true});
        }
        else if (res.data.status === 'deleted'){
          return this.router.navigate([`${PATH_URLS.deletedAccount}`, {name: res.data.user_info?.firstname, surname: res.data.user_info?.lastname}], {skipLocationChange: true}).then();
        }
        else {
          return res.data;
        }
      }),
      catchError(this.handleError())
    )
  }

  handleError<T>(): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      if (error.status === 404){
        this.router.navigate(['/404'], {skipLocationChange: true}).then();
      }
      return throwError(error);
    };
  }
}
