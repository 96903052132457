import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {AppRoutingModule, routes} from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ModalBanDisplayComponent} from "./components/admin/modal/modal-ban-display/modal-ban-display.component";
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { UniversalStorage } from './storage/universal.storage';
import { UserService } from './services/user.service';
import { ToastNoAnimationModule, ToastrModule} from "ngx-toastr";
import { Router, RouterModule, Scroll} from "@angular/router";
import { NgxMaskModule} from "ngx-mask";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgOnDestroy } from './services/onDestroy.service';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs';
import { SocketService } from './services/socket.service';
import {MainGuard} from "./guards/main.guard";
import {RoleGuard} from "./guards/role.guard";
import {UserUnbanResolver} from "./resolvers/userUnban.resolver";
import {UserBanResolver} from "./resolvers/userBan.resolver";
import {SeoResolver} from "./resolvers/seo.resolver";
import {NewsResolver} from "./resolvers/news.resolver";
import {StoryResolver} from "./resolvers/story.resolver";
import {VideoCallService} from "./services/video-call.service";
import {ModalVideoChatModule} from "./components/common/modals/modal-video-chat/modal-video-chat.module";
import {
  ModalVideoChatInvitationModule
} from "./components/common/modals/modal-video-chat-invitation/modal-video-chat-invitation.module";

@NgModule({
  declarations: [
    AppComponent,
    ModalBanDisplayComponent,
  ],
  imports: [
  BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true
    }),
    ToastNoAnimationModule.forRoot(),
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: false,
      relativeLinkResolution: 'legacy'
    }),
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true
    }),
    ToastNoAnimationModule.forRoot(),
    HttpClientModule,
    InfiniteScrollModule,
    ModalVideoChatModule,
    ModalVideoChatInvitationModule
  ],
  providers: [
    ApiService,
    UniversalStorage,
    UserService,
    MainGuard,
    NgOnDestroy,
    SocketService,
    RoleGuard,
    UserUnbanResolver,
    UserBanResolver,
    SeoResolver,
    NewsResolver,
    StoryResolver,
    VideoCallService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    router: Router,
    viewportScroller: ViewportScroller
  ) {
    viewportScroller.setOffset([0, 60]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) => {
      if (e.anchor) {
        // anchor navigation
        setTimeout(() => {
          if (e.anchor != null) {
            viewportScroller.scrollToAnchor(e.anchor);
          }
        }, 300)
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
 }
