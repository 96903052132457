import {Inject, Injectable, LOCALE_ID, PLATFORM_ID} from '@angular/core'
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {UniversalStorage} from '../storage/universal.storage';
import {PATH_URLS} from '../const';
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  error: Error = new Error();
  private readonly STORAGE_TOKEN_API_KEY = 'tokenapi';
  private readonly ERROR_TOAST_POSITION_CLASS = 'toast-top-left';
  private readonly ERROR_TOAST_DISABLE_TIMEOUT = false;

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(UniversalStorage) private appStorage: Storage,
    private toastService: ToastrService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }
// Supporting methods
  private getHeaders(): HttpHeaders {
    const token = this.getTokenFromStorage();
    return new HttpHeaders()
      .set('authorization', (token) ? 'Bearer ' + token : '');
  }
  private getTokenFromStorage(): string | null {
    return this.appStorage.getItem(this.STORAGE_TOKEN_API_KEY);
  }
  private removeTokenFromStorage(): void {
    this.appStorage.removeItem(this.STORAGE_TOKEN_API_KEY);
  }
// Requests
  public get<T>(path: string, params?: any): Observable<any | T> {
    return this.request<T>('get', path, null, params);
  }
  public post<T>(path: string, body: any | null, params?: any): Observable<any | T> {
    return this.request<T>('post', path, body, params);
  }
  public put<T>(path: string, body: any | null, params?: any): Observable<any | T> {
    return this.request<T>('put', path, body, params);
  }
  public delete<T>(path: string, params?: any): Observable<any | T> {
    return this.request<T>('delete', path, null, params);
  }
  public uploader(path: string, body: FormData,reportProgress = true): Observable<HttpEvent<any>> {
    const headers = this.getHeaders();
    const req: HttpRequest<FormData> = new HttpRequest('POST', path, body, {
      reportProgress,
      headers
    });
    return this.http.request(req).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }
  private request<T>(method: string, path: string, body: any | null, params?: any): Observable<T> {
    const headers: HttpHeaders = this.getHeaders();
    const options = { headers, params };
    switch (method) {
      case 'get':
        return this.http.get<T>(path, options).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
      case 'post':
        return this.http.post<T>(path, body, options).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
      case 'put':
        return this.http.put<T>(path, body, options).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
      case 'delete':
        return this.http.delete<T>(path, options).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
      default:
        throw new Error(`Invalid HTTP method: ${method}`);
    }
  }
// Error handling
  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this.removeTokenFromStorage();
      this.redirectToLoginPage();
    } else if (error.status >= 500) {
      const message = error.error?.message || 'Internal server error occurred.'
      this.showToastError(message);
    }
    return throwError(() => error);
  }
  private redirectToLoginPage(): void {
    this.router.navigate([PATH_URLS.login]).then();
  }

  public showToastError(message: string): void {
    this.toastService.error(message, '', {
      disableTimeOut: this.ERROR_TOAST_DISABLE_TIMEOUT,
      positionClass: this.ERROR_TOAST_POSITION_CLASS,
      timeOut: 3000
    });
  }
  public showToastSuccess(message: string): void {
    this.toastService.success(message, '', {
      disableTimeOut: this.ERROR_TOAST_DISABLE_TIMEOUT,
      positionClass: this.ERROR_TOAST_POSITION_CLASS,
      timeOut: 3000
    })
  }
}
