import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UniversalStorage} from "../storage/universal.storage";
import {ApiService} from "../services/api.service";
import {UserService} from "../services/user.service";
import {PATH_URLS} from "../const";

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
      private router: Router,
      private storage: UniversalStorage,
      private api: ApiService,
      private userService: UserService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles = route.data['roles'];
    return this.userService.user$.pipe(
      map((res: any) => {
        if (roles.indexOf(res.role) !== -1 || (Object.keys(res).length === 0 && roles.length === 0)) {
          if (res.status === 'deleted') {
            this.router.navigate([`${PATH_URLS.deletedAccount}`]).then();
          }
          return true;
        }else{
          this.router.navigate([`/${PATH_URLS.admin}/${PATH_URLS.statistics}`], {skipLocationChange: true}).then();
          return false;
        }
      })
    );
  }
}
