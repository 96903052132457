import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IUserHasRequestedVideoChat, IVideoInvitationAnswer} from "../../../../models/chats.model";
import {
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
  faPhoneSlash,
  faVideoCamera,
  faVideoSlash
} from '@fortawesome/free-solid-svg-icons';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SocketService} from "../../../../services/socket.service";
import {Subscription} from "rxjs";
import {VideoCallService} from "../../../../services/video-call.service";

@Component({
  selector: 'app-modal-video-chat-invitation',
  templateUrl: './modal-video-chat-invitation.component.html',
  styleUrls: ['./modal-video-chat-invitation.component.scss']
})
export class ModalVideoChatInvitationComponent implements AfterViewInit, OnInit, OnDestroy {
  private static numberOfOpenedDialogs : number = 0;

  private microphoneEnabled : boolean = true;
  private videoEnabled : boolean = true;
  private callClosed$: Subscription | undefined;
  private audioObj = new Audio();

  faPhone = faPhone;
  faPhoneSlash = faPhoneSlash;
  microphoneIcon = faMicrophone;
  cameraIcon = faVideoCamera;

  @Input() public modalData?: IUserHasRequestedVideoChat;
  constructor(public modal: NgbActiveModal, private socketService: SocketService, private videoCallService: VideoCallService) {
    ModalVideoChatInvitationComponent.numberOfOpenedDialogs++;
    this.audioObj.src = '../../../../../assets/audio/video-chat/request.mp3';
    this.audioObj.loop = true;
  }

  ngOnInit(): void {
     if(ModalVideoChatInvitationComponent.isAnotherWindowAlreadyOpen() || this.videoCallService.isCallStarted()) {
       console.debug("window or call already opened; ignore it");
       this.closeCall();
     }
  }

  ngAfterViewInit(): void {
    this.callClosed$ = this.socketService.onVideoCallClosed().subscribe((data) => {
      console.info("call cancelled started");
      if(this.modalData?.room_id === data.room_id) {
        // todo: change it
        console.warn("close?",this.modalData?.room_id , data.room_id);
        this.modal.close(false);
      }
    });
    this.audioObj.play().then();
  }
  ngOnDestroy(): void {
    ModalVideoChatInvitationComponent.numberOfOpenedDialogs--;
    console.info("destroy subscription");
    this.callClosed$!.unsubscribe();
    this.audioObj.pause();
  }

  changeMicrophoneMode() {
    this.microphoneEnabled = !this.microphoneEnabled;
    this.microphoneIcon =  this.microphoneEnabled ? faMicrophone: faMicrophoneSlash;
  }

  changeCameraMode() {
    this.videoEnabled = !this.videoEnabled;
    this.cameraIcon =  this.videoEnabled ? faVideoCamera: faVideoSlash;
  }

  startCall() {
    const invitationResult : IVideoInvitationAnswer = {
      start_call: true,
      microphone_enabled: this.microphoneEnabled,
      camera_enabled: this.videoEnabled
    };

    this.modal.close(invitationResult);
  }

  closeCall() {
    const invitationResult : IVideoInvitationAnswer = {
      start_call: false,
      microphone_enabled: false,
      camera_enabled: false
    };

    this.modal.close(invitationResult);
  }

  public static isAnotherWindowAlreadyOpen() : boolean {
      return ModalVideoChatInvitationComponent.numberOfOpenedDialogs > 1;
  }
}
