import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MainGuard} from "./guards/main.guard";
import {RoleGuard} from "./guards/role.guard";
import {PATH_URLS, ROLES} from './const'

export const routes: Routes = [
  {
    path: '',
    canActivate: [MainGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/main/layouts-main.module').then(m => m.LayoutsMainModule),
      },
      {
        path: '',
        canActivate: [false],
        data: {
          roles: [ROLES.user]
        },
        loadChildren: () => import('./layouts/user/layout-user.module').then(m => m.LayoutUserModule),
      },
      {
        path: '',
        canActivate: [RoleGuard],
        data: {
          roles: [ROLES.superadmin, ROLES.admin, ROLES.moderator, ROLES.seo]
        },
        loadChildren: () => import('./layouts/admin/admin-account/layout-admin-account.module').then(m => m.LayoutAdminAccountModule),
      },
      {
        path: PATH_URLS.deletedAccount,
        loadChildren: () => import('./pages/account/deleted/deleted-account.module').then(m => m.DeletedAccountModule),
        data: {
          title: 'DeletedAccount',
          description: 'DeletedAccount page'
        }
      },
      {
        path: '**',
        loadChildren: () => import('./pages/404/not-found.module').then(m => m.NotFoundModule),
        data: {
          title: '404',
          description: 'Page not found.',
        }
      },
      {
        path: '**',
        redirectTo: '404'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
