import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {ToastrService} from "ngx-toastr";
import * as SimplePeer from 'simple-peer';
import {v4 as uuidv4} from "uuid";
import {ApiService} from "./api.service";
import {API_URLS} from "../const";
import {SocketService} from "./socket.service";
import {IVideoChatData} from "../models/chats.model";

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {
  private peer?: SimplePeer.Instance;

  private localStreamBs: BehaviorSubject<MediaStream | null> = new BehaviorSubject<MediaStream | null>(null);
  public localStream$ = this.localStreamBs.asObservable();
  private remoteStreamBs: BehaviorSubject<MediaStream | null> = new BehaviorSubject<MediaStream | null>(null);
  public remoteStream$ = this.remoteStreamBs.asObservable();

  private isCallStartedBs = new BehaviorSubject<boolean>(false);
  public isCallStarted$ = this.isCallStartedBs.asObservable();

  private isVideoStartedBs = new BehaviorSubject<boolean>(false);
  public isVideoStarted$ = this.isCallStartedBs.asObservable();

  constructor(private toastrService: ToastrService, private api: ApiService, private socketService: SocketService) {
  }

  public async establishMediaCall(remotePeerId: string, roomId: string, otherParticipants: number[], microphoneEnabled: boolean, cameraEnabled: boolean) {
    try {
      if(this.isPeerInitialized()) {
        console.debug("already initialized...");
        return;
      }

      console.debug("establishMediaCall", remotePeerId, roomId );
      const self = this;
      const stream = await navigator.mediaDevices.getUserMedia({ video: cameraEnabled, audio: microphoneEnabled });
      self.peer = new SimplePeer({
        initiator: false,
        trickle: false,
        stream: stream,
        config: { iceServers: [{ urls: "stun:stun.stunprotocol.org" }] },
        channelName: roomId
      })

      this.isCallStartedBs.next(true);
      this.localStreamBs.next(stream);
      self.peer.signal(JSON.parse(remotePeerId));

      self.peer.on('signal', function (data) {
        console.debug("signal received...");

        self.socketService.sharePeerIdentifier({
          room_id: roomId,
          participants: otherParticipants,
          peer_identifier: JSON.stringify(data)
        });
      })

      self.peer.on('stream', (remoteStream) => {
        console.debug("remote stream received");
        self.isVideoStartedBs.next(true);

        // got remote video stream, now let's show it in a video tag
        this.remoteStreamBs.next(remoteStream);
      })

      self.peer!.on("close", () => {
        console.debug('close ');
        this.isVideoStartedBs.next(false);
        this.isCallStartedBs.next(false);
      });
    }
    catch (ex: any) {
      this.isCallStartedBs.next(false);
      this.showErrorMessage(ex);
      this.closeVideoCall();
    }
  }

  private showErrorMessage(ex: any) {
    this.toastrService.error(ex, '', {
      disableTimeOut: true,
      positionClass: 'toast-top-left'
    });
  }

  public async startCall(chatId: number): Promise<IVideoChatData> {
    return new Promise<IVideoChatData>((resolve, reject) => {
      try {
        if(this.isPeerInitialized()) {
          console.debug("already initialized...");
          return reject();
        }

        let roomId = uuidv4();
        let self = this;

        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
          this.isCallStartedBs.next(true);
          self.localStreamBs.next(stream);
          self.peer = new SimplePeer({
            initiator: true,
            trickle: false,
            stream: stream,
            config: { iceServers: [{ urls: "stun:stun.stunprotocol.org" }] },
            channelName: roomId
          });

          self.peer!.on('signal', function (data) {
            console.debug("signal received...");

            self.api.post(API_URLS.chatStartVideo, {
              chat_id: chatId,
              room_id: roomId,
              peer_identity: JSON.stringify(data),
              type: 'video'
            }).subscribe({
              next: (res) => {
                resolve(res.data);
              },
              complete: () => {
                console.debug("video request created");
              }, error :(ex) => {
                throw new Error(ex);
              }
            });
          });

          self.peer!.on('stream', (remoteStream) => {
            console.debug("remote stream received");
            this.isVideoStartedBs.next(true);
            // got remote video stream, now let's show it in a video tag
            this.remoteStreamBs.next(remoteStream);
          })

          self.peer!.on("connect", () => {
            console.debug('connect');
          });

          self.peer!.on("close", () => {
            console.debug('close');
            this.isVideoStartedBs.next(false);
            this.isCallStartedBs.next(false);
          });

          self.peer!.on("error", (err) => {
            console.error("internal error", err.message);
          });
        }).catch((ex) => {
          throw new Error(ex);
        });
      }
      catch (ex) {
        this.showErrorMessage(ex);
        this.isCallStartedBs.next(false);
        this.closeVideoCall();
        reject(ex);
      }
    });
  }

  private isPeerInitialized() {
    return typeof this.peer !== "undefined";
  }

  public addRemotePeer(remotePeerId: string) {
    console.debug("add remote peer...");
    this.peer!.signal(JSON.parse(remotePeerId));
  }

  public isCallStarted() {
    return this.isCallStartedBs.getValue();
  }

  public closeVideoCall() {
    console.debug("clean resources");
    this.remoteStreamBs?.value?.getTracks().forEach(track => {
      track.stop();
    });
    this.localStreamBs?.value?.getTracks().forEach(track => {
      track.stop();
    });

    this.isVideoStartedBs.next(false);
    this.isCallStartedBs.next(false);
    this.peer?.destroy();
    this.peer = undefined;
  }

  changeVideoSettings(videoEnabled: boolean): boolean {
    const videoTrack = this.localStreamBs?.value?.getTracks().find(track => track.kind === 'video');
    videoTrack!.enabled = videoEnabled;
    return videoTrack!.enabled;
  }

  changeAudioSettings(microphoneEnabled: boolean): boolean {
    const audioTrack = this.localStreamBs?.value?.getAudioTracks().find(track => track.kind === 'audio');
    audioTrack!.enabled = microphoneEnabled;
    return audioTrack!.enabled;
  }
}
