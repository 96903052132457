import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Event } from '../models/event.model';
import { environment } from '../../environments/environment.prod';
import connect from 'socket.io-client';
import { UserService } from './user.service';
import {
  IChatDeleted,
  IChatsMessages,
  IMessageDeleted, IPeerIdentifierReceived,
  ITypingMessage,
  IUserHasReadMessage,
  IUserHasRequestedVideoChat, IVideoCallCancelled
} from "../models/chats.model";

const SERVER_URL = `${environment.node}`;

@Injectable()
export class SocketService {
  private connectErrorCount = 0;
  private socket?: any;

  constructor(
    private userService: UserService,
  ) {}

  public initSocket(): void {
    this.socket = connect(SERVER_URL, {
      transports: ['websocket'],
      auth: {
        token: this.userService.getToken()
      },
      secure: true,
    });
    this.socket?.on('connect_error', (err:any) => {
      console.log(err);
      console.log('connection failed');
      this.connectErrorCount++;
      if (this.connectErrorCount >= 250){
        this.disconnect();
        console.log('stop reconnection');
      }
    });
    this.socket?.on('reconnect', () => {
      console.log('reconnect');
      this.connectErrorCount = 0;
    });
  }

  public typing(params: { chatId?: number; userId?: number }): void {
    this.socket?.emit('i am typing', params);
  }

  public onTyping(): Observable<ITypingMessage> {
    return new Observable<ITypingMessage>(observer => {
      this.socket?.on('user is typing', (data: ITypingMessage) => observer.next(data));
    });
  }

  public onMessage(): Observable<IChatsMessages> {
    return new Observable<IChatsMessages>(observer => {
      this.socket?.on('new message', (data: IChatsMessages) => observer.next(data));
    });
  }

  public onReadMessages(): Observable<IUserHasReadMessage> {
    return new Observable<IUserHasReadMessage>(observer => {
      this.socket?.on('user has read message', (data: IUserHasReadMessage) => observer.next(data));
    });
  }

  public onVideoChatRequest(): Observable<IUserHasRequestedVideoChat> {
    return new Observable<IUserHasRequestedVideoChat>(observer => {
      this.socket?.on('video request created', (data: IUserHasRequestedVideoChat) => observer.next(data));
    });
  }

  public sharePeerIdentifier(params: { room_id: string; participants: number[]; peer_identifier: string }): void {
    this.socket?.emit('share peer identifier', params);
  }

  public onPeerIdentifierReceived(): Observable<IPeerIdentifierReceived> {
    return new Observable<IPeerIdentifierReceived>(observer => {
      this.socket?.on('peer identifier received', (data: IPeerIdentifierReceived) => observer.next(data));
    });
  }

  public closeVideoCall(params: { room_id: string; participants: number[]; cancelled_user_id: number }): void {
    this.socket?.emit('close video call', params);
  }

  public onVideoCallClosed(): Observable<IVideoCallCancelled> {
    return new Observable<IVideoCallCancelled>(observer => {
      this.socket?.on('video call closed', (data: IVideoCallCancelled) => observer.next(data));
    });
  }

  public messagesDeleted(): Observable<IMessageDeleted> {
    return new Observable<IMessageDeleted>(observer => {
      this.socket.on('message deleted', (data: IMessageDeleted) => observer.next(data));
    });
  }

  public chatDeleted(): Observable<IChatDeleted> {
    return new Observable<IChatDeleted>(observer => {
      this.socket.on('chat deleted', (data: IChatDeleted) => observer.next(data));
    });
  }

  public onEvent(event: Event): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket?.on(event, () => observer.next());
    });
  }

  public disconnect() {
    if (this.socket) {
      this.socket?.disconnect();
    }
  }

}
