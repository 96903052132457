import {Component, Inject, Input} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-modal-ban-display',
  templateUrl: './modal-ban-display.component.html',
  styleUrls: ['./modal-ban-display.component.scss']
})
export class ModalBanDisplayComponent {
  @Input() duration?: string;

  constructor(
    public modal: NgbActiveModal,
    @Inject(DOCUMENT) private document: Document
  ) {
  }
}
