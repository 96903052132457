import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {API_URLS} from "../const";
import {ApiService} from "../services/api.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable()

export class NewsResolver implements Resolve<any> {
  constructor(
    private api: ApiService,
    private router: Router,
    ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (Number(route.params['id'])){
      return this.api.get(`${API_URLS.news}/${route.params['id']}`, true).pipe(
        map((res) => {
          return res.data;
        }),
        catchError(this.handleError())
      )
    } else {
      return false;
    }
  }

  handleError<T>(): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      if (error.status === 404){
        this.router.navigate(['/404'], {skipLocationChange: true}).then();
      }
      return throwError(error);
    };
  }
}
