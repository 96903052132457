import {environment} from "../environments/environment.prod";

export const API_URLS = {
  createUser: `${environment.apiUrl}/v1/user`,
  currentUser: `${environment.apiUrl}/v1/user`,
  users: `${environment.apiUrl}/v1/users`,
  login: `${environment.apiUrl}/v1/user/login`,
  logout: `${environment.apiUrl}/v1/user/logout`,
  accountRollback: `${environment.apiUrl}/v1/userDeleteRollback`,
  contactRequest: `${environment.apiUrl}/v1/contactRequest`,
// Chats
  chats: `${environment.apiUrl}/v1/chats`,
  chatSendMessage: `${environment.apiUrl}/v1/chats/messages`,
  chatStartVideo: `${environment.apiUrl}/v1/chats/video`,
// CharacterTraits
  characterTraits: `${environment.apiUrl}/v1/characterTraits`,
  userCharacterTraits: `${environment.apiUrl}/v1/userCharacterTraits`,

  setPassword: `${environment.apiUrl}/v1/user/setPassword`,
  resetPassword: `${environment.apiUrl}/v1/user/forgotPassword`,
  emailExists: `${environment.apiUrl}/v1/user/emailOrLoginExists`,
// Interests
  userInterestsItem: `${environment.apiUrl}/v1/interestItem`,
  userInterestsTypes: `${environment.apiUrl}/v1/interestTypes`,
  userInterestsType: `${environment.apiUrl}/v1/interestType`,
  userInterests: `${environment.apiUrl}/v1/userInterests`,
  userInterest: `${environment.apiUrl}/v1/userInterest`,
// Introduce
  userIntroduce: `${environment.apiUrl}/v1/userIntroduce`,
// User
  userMatches: `${environment.apiUrl}/v1/userMatches`,
  userBanList: `${environment.apiUrl}/v1/userBanList`,
// Favorite
  userFavorites: `${environment.apiUrl}/v1/userFavorites`,
// Countries
  countries: `${environment.apiUrl}/v1/countries`,
// Cities
  cities: `${environment.apiUrl}/v1/cities`,
// Access
  access: `${environment.apiUrl}/v1/access`,
// Statistics
  statistics: `${environment.apiUrl}/v1/userStatistics`,
// Seo
  pages: `${environment.apiUrl}/v1/pages`,
  pageItem: `${environment.apiUrl}/v1/pageItem`,
// News
  news: `${environment.apiUrl}/v1/news`,
  newsItem: `${environment.apiUrl}/v1/newsItem`,
// Stories
  stories: `${environment.apiUrl}/v1/stories`,
  storiesItem: `${environment.apiUrl}/v1/storiesItem`,
// Storage
  chatAttachments: `${environment.storage}/api/images/messages`,
  removeMessageMedia: `${environment.storage}/api/images/removeMessageMedia`,
  removeMedia: `${environment.storage}/api/images/removeMedia`,
// StoreMedia (News or Stories)
  storeMedia:  `${environment.storage}/api/images/storeMedia`,
  // Translate
  translate: `${environment.apiUrl}/v1/translate`,
};
export const PATH_URLS = {
  home: `/`,
  login: '/',
  register: 'register',
  forgotPassword: 'forgot-password',
  createPassword: 'create-password',
  successConfirmation: 'success-confirmation',
  listMatches: 'list-matches',
  about: 'about',
  deletedAccount: 'deleted-account',
  dataPolicy: 'data-policy',
  imprint: 'imprint',
  comingSoon: 'coming-soon',
// User
  user: 'user',
// Chats
  chat: 'chat',
  blacklist: 'blacklist',
// Questionnaire
  questionnaire: 'questionnaire',
  questionnaireSuccessfully: 'questionnaire-successfully',
// Personal Info
  userCreatePersonalInfo: 'create-personal-info',
  userPersonalOptions: 'personal-options',
  userIntroduction: 'introduction',
// Admin
  admin: 'admin',
  statistics: 'statistics',
  users: 'users',
  bannedUsers: 'banned-users',
  settings: 'settings',
  seoManagement: 'seo-management',
  edit: 'edit',
  seo: 'seo',
  news: 'news',
  stories: 'stories',
  create: 'create',
  article: 'article',
  story: 'story',
  requests: 'requests',
};

export const ROLES = {
  superadmin: 'superadmin',
  admin: 'admin',
  moderator: 'moderator',
  seo: 'seo',
  user: 'user'
}
