import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalVideoChatComponent } from "./modal-video-chat.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {faCircle, faPhone, faPhoneSlash, fas, faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash} from "@fortawesome/free-solid-svg-icons";

@NgModule({
    declarations: [
      ModalVideoChatComponent,
    ],
    exports: [
      ModalVideoChatComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FontAwesomeModule
    ]
})
export class ModalVideoChatModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faPhone, faPhoneSlash, faCircle, faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash);
    library.addIconPacks(fas);
  }
}
