<div class="modal-body">
  <div class="title-wrapper">
    <div class="invitation-title">
      {{modalData?.author_name}} is calling you
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="video-wrapper">
    <div class="video-content-bottom">
      <div class="modal-footer buttons">
        <div style="font-size: 1.8em;">
          <ul>
            <li>
              <div class="video-normal-button">
                <fa-stack>
                  <fa-icon icon="circle" stackItemSize="2x"></fa-icon>
                  <fa-icon [icon]="cameraIcon" [inverse]="true" stackItemSize="1x" (click)="changeCameraMode()"></fa-icon>
                </fa-stack>
              </div>
            </li>
            <li>
              <div class="video-normal-button">
                <fa-stack>
                  <fa-icon icon="circle" stackItemSize="2x"></fa-icon>
                  <fa-icon [icon]="microphoneIcon" [inverse]="true" stackItemSize="1x" (click)="changeMicrophoneMode()"></fa-icon>
                </fa-stack>
              </div>
            </li>
            <li>
              <div class="video-start-button">
                <fa-stack>
                  <fa-icon icon="circle" stackItemSize="2x"></fa-icon>
                  <fa-icon icon="phone" [inverse]="true" stackItemSize="1x" (click)="startCall()"></fa-icon>
                </fa-stack>
              </div>
            </li>
            <li>
              <div class="video-close-button">
                <fa-stack>
                  <fa-icon icon="circle" stackItemSize="2x"></fa-icon>
                  <fa-icon icon="phone" [inverse]="true" stackItemSize="1x" (click)="closeCall()" [rotate]="90"></fa-icon>
                </fa-stack>
              </div>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</div>
