import { IUser } from '../models/user.model';
import {Inject, Injectable} from '@angular/core';
import { ApiService } from './api.service';
import {API_URLS, PATH_URLS} from '../const';
import {BehaviorSubject} from 'rxjs';
import {UniversalStorage} from '../storage/universal.storage';


@Injectable()
export class UserService {
  user$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>({} as IUser);

  constructor(
    @Inject(UniversalStorage) private appStorage: Storage,
    private api: ApiService,
  ) { }

  loadUser() {
    return this.api.get(API_URLS.currentUser);
  }

  getToken() {
    return this.appStorage.getItem('tokenapi');
  }

  setToken(token: string) {
    this.appStorage.setItem('tokenapi', token);
  }

  logout() {
    this.appStorage.removeItem('tokenapi');
    location.href = `${PATH_URLS.home}`;
  }
}
