<div class="video-wrapper">
  <video #remoteVideo id="remote-video" poster="/assets/images/video/video-placeholder.jpg" autoplay playsinline></video>
  <video #localVideo id="local-video" autoplay playsinline [muted]=true></video>
  <div class="video-content-bottom">
    <div class="video-controls">
      <ul>
        <li>
          <div class="video-normal-button">
            <fa-stack>
              <fa-icon icon="circle" stackItemSize="2x"></fa-icon>
              <fa-icon [icon]="cameraIcon" [inverse]="true" stackItemSize="1x" (click)="changeCameraMode()"></fa-icon>
            </fa-stack>
          </div>
        </li>
        <li>
          <div class="video-normal-button">
            <fa-stack>
              <fa-icon icon="circle" stackItemSize="2x"></fa-icon>
              <fa-icon [icon]="microphoneIcon" [inverse]="true" stackItemSize="1x" (click)="changeMicrophoneMode()"></fa-icon>
            </fa-stack>
          </div>
        </li>
        <li>
          <button type="button" class="btn video-close-button" (click)="modal.close(true)">
              <span><fa-icon
                [icon]="phone"
                (click)="modal.close(true)"
                [styles]="{ stroke: 'white', color: 'white' }"></fa-icon>&nbsp;Close</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
