import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {faCircle, faPhone, faPhoneSlash, fas} from "@fortawesome/free-solid-svg-icons";
import { ModalVideoChatInvitationComponent } from './modal-video-chat-invitation.component';

@NgModule({
    declarations: [
      ModalVideoChatInvitationComponent,
    ],
    exports: [
      ModalVideoChatInvitationComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FontAwesomeModule
    ]
})
export class ModalVideoChatInvitationModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faPhone, faPhoneSlash, faCircle);
    library.addIconPacks(fas);
  }
}
