import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PATH_URLS} from '../const';
import {map} from 'rxjs/operators';
import {UniversalStorage} from "../storage/universal.storage";
import {ApiService} from "../services/api.service";
import {UserService} from "../services/user.service";

@Injectable()
export class MainGuard implements CanActivate {
  constructor(
      private router: Router,
      private storage: UniversalStorage,
      private api: ApiService,
      private userService: UserService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.storage.getItem('tokenapi');
    if (!token) {
      return true;
    } else {
      return this.userService.loadUser().pipe(
        map((res: any) => {
          this.userService.user$.next(res.data);
          return true;
        })
      );
    }
  }
}
