import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {SocketService} from "./services/socket.service";
import {isPlatformBrowser} from "@angular/common";
import { PushNotificationsService } from './services/pushNotifications.service';
import {UserService} from "./services/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private socketService: SocketService,
    private userService: UserService,
    private pushNotificationsService: PushNotificationsService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.userService.user$.subscribe((user) => {
      if(user.id && isPlatformBrowser(this.platformId)) {
        this.socketService.initSocket();
      }
    });
    this.pushNotificationsService.initPush();
  }
}
