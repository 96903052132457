import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {filter, Observable, Subscription} from "rxjs";
import {VideoCallService} from "../../../../services/video-call.service";
import {
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
  faVideoCamera,
  faVideoSlash
} from '@fortawesome/free-solid-svg-icons';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SocketService} from "../../../../services/socket.service";
import {UserService} from "../../../../services/user.service";
import {IVideoChatData} from "../../../../models/chats.model";
import {ApiService} from "../../../../services/api.service";

@Component({
  selector: 'app-modal-video-chat',
  templateUrl: './modal-video-chat.component.html',
  styleUrls: ['./modal-video-chat.component.scss']
})
export class ModalVideoChatComponent implements AfterViewInit, OnDestroy {
  public isVideoStarted$: Observable<boolean>;
  private microphoneEnabled : boolean = true;
  private cameraEnabled : boolean = true;
  phone = faPhone;
  microphoneIcon = faMicrophone;
  cameraIcon = faVideoCamera;

  @Input() public modalData?: IVideoChatData;
  @ViewChild('localVideo') localVideo!: ElementRef<HTMLVideoElement>;
  @ViewChild('remoteVideo') remoteVideo!: ElementRef<HTMLVideoElement>;
  private callClosed$: Subscription | undefined;
  private peerReceiver$: Subscription | undefined;
  private audioObj = new Audio();
  private isVideoStartedSubscription$: Subscription;

  constructor(private videoCallService: VideoCallService,
              private api: ApiService,
              public modal: NgbActiveModal,
              private socketService: SocketService,
              private userService: UserService) {
    this.isVideoStarted$ = this.videoCallService.isVideoStarted$;

    this.audioObj.src = '../../../../../assets/audio/video-chat/call.mp3';
    this.audioObj.loop = true;
    this.isVideoStartedSubscription$ = this.isVideoStarted$.subscribe((res) => {
      console.debug("video started", res)
    });
  }

  ngAfterViewInit(): void {
    this.videoCallService.localStream$
      .pipe(filter(res => !!res))
      .subscribe(stream => this.localVideo.nativeElement.srcObject = stream);

    this.videoCallService.remoteStream$
      .pipe(filter(res => !!res))
      .subscribe(stream => this.remoteVideo.nativeElement.srcObject = stream);

    this.callClosed$ = this.socketService.onVideoCallClosed().subscribe((data) => {
      console.debug("video closed");
      if(this.modalData?.room_id === data.room_id) {
        console.debug("another room, call cancelled", this.modalData, data.room_id);
        this.modal.close(false);
      }
    });

    this.peerReceiver$ = this.socketService.onPeerIdentifierReceived().subscribe((data) => {
      if(this.modalData?.room_id === data.room_id) {
        this.audioObj.pause();
        this.videoCallService.addRemotePeer(data.peer_identifier);
      } else {
        console.debug("Peer identifier: another room, call cancelled", this.modalData, data);
      }
    });
  }

  ngOnDestroy(): void {
    console.debug("destroy modal");
    this.closeVideoCall();
    this.callClosed$!.unsubscribe();
    this.peerReceiver$!.unsubscribe();
    this.isVideoStartedSubscription$.unsubscribe();
  }

  public joinCall(remotePeerId: string, roomId: string, otherParticipants: number[], microphoneEnabled: boolean, cameraEnabled: boolean): void {
    this.videoCallService.establishMediaCall(remotePeerId, roomId, otherParticipants, microphoneEnabled, cameraEnabled).then((res) => {
      this.microphoneEnabled = microphoneEnabled;
      this.cameraEnabled = cameraEnabled;
      this.updateMicrophoneIcon();
      this.updateCameraIcon();
    }).catch((reason: any) => reason)
  }

  changeMicrophoneMode() {
    this.microphoneEnabled = !this.microphoneEnabled;
    this.microphoneEnabled = this.videoCallService.changeAudioSettings(this.microphoneEnabled);
    this.updateMicrophoneIcon();
  }

  private updateMicrophoneIcon() {
    this.microphoneIcon = this.microphoneEnabled ? faMicrophone : faMicrophoneSlash;
  }

  changeCameraMode() {
    this.cameraEnabled = !this.cameraEnabled;
    this.cameraEnabled = this.videoCallService.changeVideoSettings(this.cameraEnabled);
    this.updateCameraIcon();
  }

  private updateCameraIcon() {
    this.cameraIcon = this.cameraEnabled ? faVideoCamera : faVideoSlash;
  }

  closeVideoCall() {
    this.audioObj.pause();
    this.videoCallService.closeVideoCall();
    this.socketService.closeVideoCall({
      room_id: this.modalData?.room_id!,
      participants: this.getCallParticipants(),
      cancelled_user_id: this.userService.user$.getValue().id
    });
  }

  getCallParticipants(): number[] {
    if(typeof this.modalData === "undefined") {
      return [];
    }

    let participants = this.modalData!.participants.slice();
    const currentUserId = this.userService.user$.getValue().id;
    participants.push(this.modalData!.author_id);
    return participants.filter(item => item !== currentUserId);
  }

  public startCall(chatId: number): void {
    this.audioObj.play().then();
    const self = this;
    this.videoCallService.startCall(chatId).then((data: IVideoChatData) => {
      console.debug("room data", data);
      self.modalData = data;
    }).catch((reason: any) => reason)
  }
}
